<template>
    <div v-loading.fullscreen.lock="loading">
        <div class="header-section">
            <div class="login-logo mb-4">
                <img class="logo_img" src="/assets/images/logo-img.png"/>
            </div>
            <div class="toplogin-btn">
                <router-link class="base-button btn btn-default login-text px-2" to="/login">Login</router-link>
            </div>
            <div class="toplogin-btn">
                <router-link class="base-button btn btn-default mr-2 px-2 signup-text" to="/signup">Sign up</router-link>
            </div>
        </div>
        <!--Banner Section--->
        <section class="banner-section">
            <div class="container">
                <div class="banner-caption">
                    <h2>DRA Training Platform</h2>
                    <p>Industry training solutions to fit the needs of current or future restaurant employees.</p>
                </div>
            </div>
        </section>
        <!--Banner Section--->
        <!-- Page content -->
        <div class="content-area" style="padding-top: 20px; padding-bottom: 50px;">
            <div class="container-fluid container-fluid-custom">
                <h5 class="mb-5 text-center">Sign up today to earn position-specific certificates, or complete individual trainings for required courses like Online Alcoholic Beverage Server Training.</h5>
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-lg-5 form-section">
                                <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                                    <div v-if="showCompanyinformation">
                                        <form role="form" @submit.prevent="handleSubmit(showAgreement)">
                                            <div class="form-row">
                                                <div class="col-md-6">
                                                    <label class="form-control-label">Company Type </label><br/>
                                                    <el-select class="mr-3" style="width: 100%" placeholder="Select Company Type" rules="required" v-model="company.company_type">
                                                        <el-option v-for="(option, index) in company_types" class="select-primary" :value="option.value" :label="option.label" :key="'company_type_' + index"></el-option>
                                                    </el-select>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="form-control-label">Company Name <span class="req"> *</span></label>
                                                    <base-input type="text" name="Company name" placeholder="Company Name" rules="required" v-model="company.name"></base-input>
                                                </div>
                                                <div class="col-md-6">
                                                    <base-input type="text" label="Website" name="Website" placeholder="Website" v-model="company.website"></base-input>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="form-control-label">Company Logo</label>
                                                    <form>
                                                        <file-input v-on:change="onImageChange"></file-input>
                                                    </form>
                                                </div>
                                                <div class="col-md-3">
                                                    <label class="form-control-label">Address <span class="req"> *</span></label>
                                                    <base-input type="text" name="Address" placeholder="Address" rules="required" v-model="company.address_1"></base-input>
                                                </div>
                                                <div class="col-md-3">
                                                    <label class="form-control-label">City <span class="req"> *</span></label>
                                                    <base-input type="text" name="City" placeholder="city" rules="required" v-model="company.city"></base-input>
                                                </div>
                                                <div class="col-md-3">
                                                    <label class="form-control-label">State <span class="req"> *</span></label>
                                                    <base-input type="text" name="State" placeholder="State" rules="required" v-model="company.state"></base-input>
                                                </div>
                                                <div class="col-md-3">
                                                    <label class="form-control-label">Zip code <span class="req"> *</span></label>
                                                    <base-input type="number" name="Zip code" placeholder="Zip" rules="required" v-model="company.zip"></base-input>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <h3 style="color: #444c57" class="mt-4 ml-2">Administrator Login Information</h3>
                                            </div>
                                            <hr/>
                                            <div class="form-row">
                                                <div class="col-md-4">
                                                    <label class="form-control-label">First Name <span class="req"> *</span></label>
                                                    <base-input type="text" name="First Name" placeholder="First Name" rules="required" v-model="company.first_name"></base-input>
                                                </div>
                                                <div class="col-md-4">
                                                    <label class="form-control-label">Last Name <span class="req"> *</span></label>
                                                    <base-input type="text" name="Last Name" placeholder="Last Name" rules="required" v-model="company.last_name"></base-input>
                                                </div>
                                                <div class="col-md-4">
                                                    <label class="form-control-label">Phone <span class="req"> *</span></label>
                                                    <base-input name="Phone Number" placeholder="Phone" rules="required" v-model="company.telephone_no" @input="acceptNumber"></base-input>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="form-control-label">Email Address <span class="req"> *</span></label>
                                                    <base-input type="email" name="Email Address" placeholder="Email Address" rules="required" v-model="company.email"></base-input>
                                                </div>

                                                <div class="col-md-5">
                                                    <label class="form-control-label">Password <span class="req"> *</span></label>
                                                    <base-input :type="passwordFieldType" v-if="!(company_id !== '')" name="Password" placeholder="Password" rules="required" v-model="company.password"></base-input>
                                                    <base-input :type="passwordFieldType" v-if="company_id !== ''" name="Password" placeholder="Password" v-model="company.password"></base-input>
                                                </div>
                                                <div class="col-md-1 password-eye" style="margin-top: 40px">
                                                    <span @click.prevent="switchVisibility"><i class="fa fa-eye" title="Show Password"></i></span>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <h3>AUTHORIZATION</h3>
                                                <p>
                                                    <small> By clicking the "Create Account" button below, you agree that the name you typed in the box above (Your Full Name) will be the electronic representation of your signature for all purposes in relation to the Train 321, LLC legally binding Service Activation Agreement. You agree that your electronic signature is considered an original, for purposes of entering into a contract. </small>
                                                </p>
                                            </div>
                                            <div class="text-center">
                                                <button type="submit" class="btn btn-primary mt-4 mb-5" v-if="showReEstimatButton === false">Continue</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label class="form-control-label">First Name <span class="req"> *</span></label>
                                                    <base-input type="text" name="First Name" placeholder="First Name" rules="required" v-model="company.first_name"></base-input>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="form-control-label">Last Name <span class="req"> *</span></label>
                                                    <base-input type="text" name="Last Name" placeholder="Last Name" rules="required" v-model="company.last_name"></base-input>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="form-control-label">Phone <span class="req"> *</span></label>
                                                    <base-input name="Phone Number" placeholder="Phone" rules="required" v-model="company.telephone_no" @input="acceptNumber"></base-input>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="form-control-label">Email Address <span class="req"> *</span></label>
                                                    <base-input type="email" name="Email Address" placeholder="Email Address" rules="required" v-model="company.email"></base-input>
                                                </div>
                                                <div class="col-md-12">
                                                    <label class="form-control-label">Company Name <span class="req"> *</span></label>
                                                    <base-input type="text" name="Company name" placeholder="Company Name" rules="required" v-model="company.name"></base-input>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="form-control-label"># of Locations <span class="req"> *</span></label>
                                                    <base-input type="number" min="1" name="Location" rules="required" v-model="company.no_of_locations"></base-input>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="form-control-label">Estimated # of Users <span class="req"> *</span></label>
                                                    <base-input type="number" min="1" name="Users " rules="required" v-model="company.no_of_employees"></base-input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12" style="font-style: italic">
                                            <h5 class="reduceFont"><span class="text-danger">*</span>Indicates a required field. The estimated pricing is based upon the number of locations, users and courses selected for your company.</h5>
                                        </div>
                                    </div>
                                </validation-observer>
                            </div>
                            <div class="col-lg-4">
                                <div class="row">
                                    <div class="col-md-12">
                                        <base-checkbox v-model="already_member"><b>Already a member of the DRA?</b></base-checkbox>
                                    </div>
                                    <div class="col-md-11 mt-1" style="margin-left: 5%">
                                        <h5>
                                            Not a member,
                                            <a class="linkColor" target="_blank" href="https://www.delawarerestaurant.org/membership/"> click here </a>
                                            to join.
                                        </h5>
                                    </div>
                                    <div ref="cousesContainer" class="col-md-12 mt-2">
                                        <el-checkbox-group v-model="checked_courses">
                                            <span v-for="option in course" :key="option.id">
                                                <el-checkbox :label="option.id" @change="handleCourseSelection($event, option)">{{ option.course_name }}</el-checkbox><br/>
                                            </span>
                                        </el-checkbox-group>
                                        <el-checkbox-group v-model="checked_coursefolders">
                                            <span v-for="option in coursefolders" :key="option.id">
                                                <el-popover :ref="`fromPopOver${option.id}`" placement="top-start" width="500" trigger="hover" :key="option.id" :id="option.id">
                                                    <div class="provideScroller">
                                                        <ul>
                                                            <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                                {{ courses.name }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </el-popover>
                                                <el-checkbox :label="option.id" @change="courseFolderChecked">
                                                    {{ option.coursefolder_name }}
                                                </el-checkbox>
                                                <span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span><br/>
                                            </span>
                                        </el-checkbox-group>
                                        <el-checkbox-group v-model="checked_other_courses">
                                            <span v-for="option in basicPackage1" :key="option.id">
                                                <el-popover :ref="`fromPopOver${option.id}`" placement="top-start" width="500" trigger="hover" :key="option.id" :id="option.id">
                                                    <div class="provideScroller">
                                                        <ul>
                                                            <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                                {{ courses.name }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </el-popover>
                                                <el-checkbox :label="option.id">{{ option.coursefolder_name }}</el-checkbox>
                                                <span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span>
                                            </span>
                                        </el-checkbox-group>
                                        <el-checkbox-group v-model="checked_other_courses">
                                            <span v-for="option in basicPackage2" :key="option.id">
                                                <el-popover :ref="`fromPopOver${option.id}`" placement="top-start" width="500" trigger="hover" :key="option.id" :id="option.id">
                                                    <div class="provideScroller">
                                                        <ul>
                                                            <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                                {{ courses.name }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </el-popover>
                                                <el-checkbox :label="option.id" v-if="checked_coursefolders.length > 0" disabled>{{ option.coursefolder_name }}</el-checkbox>
                                                <el-checkbox :label="option.id" v-else-if="checked_coursefolders.length == 0">{{ option.coursefolder_name }}</el-checkbox
                                                ><span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span><br/>
                                            </span>
                                        </el-checkbox-group>
                                    </div>
                                    <br>
                                    <div ref="move-to"></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="text-center pb-3">
                                            <base-button class="mt-2" v-if="showReEstimatButton || !lead_id" @click="reEstimatePrice">{{ !lead_id ? 'Calculate' : 'Re-estimate' }}</base-button>
                                            <base-button class="mt-2" v-if="(checked_courses.length || checked_coursefolders.length || checked_other_courses.length) && lead_id && !showCompanyinformation" @click.prevent="checkIfCRAMember" :disabled="disableSignUpButton">Sign Up</base-button>
                                        </div>
                                    </div>
                                </div>
                                <serv-safe-food-handler/>
                            </div>
                            <div class="col-lg-3">
                                <div class="row" v-for="(promoCode, index) in promoCodes" :key="index">
                                    <div class="col-md-10">
                                        <div class="form-group promo_inputBOX">
                                            <label>Promo Code
                                                <input type="text" class="form-control" id="promo-code" placeholder="Promo Code" v-model="promoCodes[index]">
                                            </label>
                                        </div>

                                        <p class="paragraph-tag">If you have not received a promo code for the alcohol or drug awareness course, please <a href="https://drapromos.com/">click here.</a></p>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="promoBTN_BOX">
                                            <button class="btn promoBTN_Minus" v-if="(promoCodes.length > 1)" @click="removePromoCode(index)">-</button>
                                            <button class="btn promoBTN" v-if="promoCodes.length === (index + 1)" @click="addPromoCode">+</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <base-button class="btn-success" @click.prevent="applyPromoCode">Apply Coupon</base-button>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <!--Both Course Folder and Courses Is Selected-->
                                    <div class="row payable-content" v-if="checked_courses.length > 0 && checked_coursefolders.length > 0">
                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">Course Folders - Cost Per Month:</label>
                                        </div>
                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">{{ formatPrice(pricePerCourseFolderPerMonth) }}</label>
                                        </div>

                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">Total Cost of Other Courses:</label>
                                        </div>
                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">{{ formatPrice(totalCostOfCourses) }}</label>
                                        </div>

                                        <div class="col-md-6 col-6" v-if="discount">
                                            <label class="form-control-label">Promo Code Discount:</label>
                                        </div>
                                        <div class="col-md-6 col-6" v-if="discount">
                                            <label class="form-control-label">{{ formatPrice(discount) }}</label>
                                        </div>

                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label" style="text-decoration:underline; font-weight:bold;">Total Cost Per Month:</label>
                                        </div>
                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label" style="text-decoration:underline; font-weight:bold;">{{ formatPrice(totalPricePerMonth) }}</label>
                                        </div>

                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">Price if paid in full for the year:</label>
                                        </div>
                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">{{ formatPrice(totalPricePerYear) }}</label>
                                        </div>
                                    </div>
                                    <!--Only Courses Folder Is Selected-->
                                    <div class="row payable-content" v-if="checked_courses.length === 0 && checked_coursefolders.length > 0">
                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">Cost Per Month:</label>
                                        </div>
                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">{{ formatPrice(pricePerCourseFolderPerMonth) }}</label>
                                        </div>

                                        <div class="col-md-6 col-6" v-if="discount">
                                            <label class="form-control-label">Promo Code Discount:</label>
                                        </div>
                                        <div class="col-md-6 col-6" v-if="discount">
                                            <label class="form-control-label">{{ formatPrice(discount) }}</label>
                                        </div>

                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">Total Cost Per Month:</label>
                                        </div>
                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">{{ formatPrice(totalPricePerMonth) }}</label>
                                        </div>

                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label" style="text-decoration:underline; font-weight:bold;">Total Cost Per Month:</label>
                                        </div>
                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label" style="text-decoration:underline; font-weight:bold;">{{ formatPrice(totalPricePerMonth) }}</label>
                                        </div>

                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">Price if paid in full for the year:</label>
                                        </div>
                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">{{ formatPrice(perYearCost) }}</label>
                                        </div>
                                    </div>
                                    <!--Only Courses Is Selected-->
                                    <div class="row payable-content" v-if="checked_courses.length > 0 && checked_coursefolders.length === 0">
                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">Total Cost of Courses:</label>
                                        </div>
                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label">{{ formatPrice(perYearCost) }}</label>
                                        </div>

                                        <div class="col-md-6 col-6" v-if="discount">
                                            <label class="form-control-label">Promo Code Discount:</label>
                                        </div>
                                        <div class="col-md-6 col-6" v-if="discount">
                                            <label class="form-control-label">{{ formatPrice(discount) }}</label>
                                        </div>

                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label" style="text-decoration:underline; font-weight:bold;">Total Cost:</label>
                                        </div>
                                        <div class="col-md-6 col-6">
                                            <label class="form-control-label" style="text-decoration:underline; font-weight:bold;">{{ formatPrice(totalPricePerYear) }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal :show.sync="agreementModal" class="user-modal">
            <h3 slot="header">Service Activation Agreement</h3>
            <form>
                <div class="agreement-content">
                    <agreement type="company"></agreement>
                </div>
                <div class="text-center mt-2">
                    <base-button type="success" @click.prevent="finalCreateAccount"> I Agree</base-button>
                    <base-button type="danger" @click.prevent="cancelAgreement"> Cancel</base-button>
                </div>
            </form>
        </modal>
        <modal :show.sync="showPaymentOption">
            <h4 slot="header" style="color: #444c57" class="title title-up text-center">Payment</h4>
            <credit-card v-if="showPaymentOption" type="company" :monthlyAmount="monthlyCharge" :yearlyAmount="yearlyCharge" :city="company.city" :state="company.state" :address="company.address_1" :zip="company.zip" :enablePaymentButton="enablePaymentButton" v-on:payClicked="payClicked"/>
        </modal>

        <modal :show.sync="showCourseModal">
            <h4 slot="header" style="color:#444C57" class="title title-up text-center">
                {{ currentCheckedCourse.courseName }}
            </h4>
            <div class="col-md-12" v-if="currentCheckedCourse.showUserFiled">
                <base-input label="How many employees will be taking this course:" type="number" name="#Employees" min="0" v-model="currentCheckedCourse.numberOfUsers"/>
            </div>
            <div class="col-md-12" v-if="currentCheckedCourse.showManagerFiled">
                <base-input label="How many managers will be taking this course:" type="number" name="#Managers" min="0" v-model="currentCheckedCourse.numberOfManager"/>
            </div>
            <div class="col-md-12">
                <base-button size="md" class="custom-btn right" @click.prevent="handleAddUserCountToCourse">Done</base-button>
            </div>
        </modal>

        <modal :show.sync="paymentModalMessage">
          <h1 class="text-capitalize text-center text-strong m-0 payment-modal-text text-danger font-weight-500">Your payment is processing...</h1>
      </modal>

        <the-footer/>
    </div>
</template>

<script>
import FileInput from "@/components/Inputs/FileInput";
import {Checkbox, CheckboxGroup, Option, OptionGroup, Select, Table, TableColumn} from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import CreditCard from "@/views/Widgets/CreditCard";
import Agreement from "./Agreement.vue";
import {Dynamic} from "../../wl";
import TheFooter from '@/views/Layout/AppFooter.vue';
import ServSafeFoodHandler from './ServSafeFoodHandler.vue';

export default {
    name: "register",
    components: {
        Agreement,
        FileInput,
        CreditCard,
        [Select.name]: Select,
        [Option.name]: Option,
        [OptionGroup.name]: OptionGroup,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        TheFooter,
        ServSafeFoodHandler,
    },
    data() {
        return {
            loading: false,
            paymentModalMessage: false,
            users: 0,
            already_member: false,
            member_id: "",
            courseSelectionFocused: false,
            courseFolderSelectionFocused: false,
            focused: "blue-theme",
            formData: {
                company_name: "",
                first_name: "",
                last_name: "",
                company_location_num: "",
                company_employee_num: "",
                company_address_1: "",
                company_address_2: "",
                company_phone: "",
                company_email: "",
                company_zip: "",
                website: "",
                company_type: "",
                username: "",
                parent_id: "",
                image: "",
                company_city: "",
                company_state: "",
                company_password: "",
                promo_code: "",
            },
            baseUrl: this.$baseUrl,
            hot_user: "",
            hot_token: "",
            config: "",
            company_id: "",
            creatAccountClicked: false,
            company_types: [],
            image: "",
            sub_total: "",
            special_courses: [],
            company: {
                first_name: "",
                last_name: "",
                company_type: "",
                parent_company: "",
                name: "",
                administrator: "",
                no_of_locations: "",
                no_of_employees: "",
                address_1: "",
                address_2: "",
                city: "",
                state: "",
                zip: "",
                logo: "",
                telephone_no: "",
                email: "",
                password: "",
            },

            search: "",
            contactUsModal: false,
            agreementModal: false,
            passwordFieldType: "password",
            courses: [],
            coursefolders: [],
            basicPackage1: [],
            basicPackage2: [],
            services: [],
            contact: {
                name: "",
                phone: "",
                email: "",
                message: "",
            },
            siteName: "",
            infoEmail: "",
            lead_id: "",
            checked_courses: [],
            checked_other_courses: [],
            checked_coursefolders: [],
            companyEstimateDetailModel: false,
            showCompanyinformation: false,
            showPricePlan: false,
            total_cost: "",
            total_discount: "",
            perYearCost: "",
            employees_count: "",
            locations_count: "",
            course: "",
            role: "",
            showPaymentOption: false,
            onlySexualCourse: 1,
            inputStyle: "",
            showPromoField: false,
            showReEstimatButton: false,
            disableSignUpButton: false,
            promo_code: '',
            isPromoCodeValid: false,
            showDiscountDetail: false,
            promoCodeDetails: {
                discount_percentage: 0,
                discount_fixed_price: 0,
                type: '',
            },
            isSignupButtonClicked: false,
            alcoholCourses: [],
            drugCourses: [],
            sexualHarassmentCourses: [],
            showAlcoholCourseModal: false,
            showAlcoholCourseModalCount: 0,
            showDrugCourseModal: false,
            showDrugCourseModalCount: 0,
            showSexualHarassmentCourseModal: false,
            showSexualHarassmentCourseModalUserCount: 0,
            showSexualHarassmentCourseModalManagerCount: 0,
            selectedCoursesData: [],
            currentSelectedCourseData: {
                course_name: '',
            },
            enablePaymentButton: false,
            totalPricePerYear: 0,
            totalPricePerMonth: 0,
            showCourseModal: false,
            currentSelectedCourse: 0,
            currentCheckedCourse: {
                courseName: '',
                showUserFiled: '',
                numberOfUsers: 0,
                showManagerFiled: '',
                numberOfManager: 0
            },
            promoCodes: [
                ''
            ],
            pricePerCourseFolderPerMonth: 0,
            pricePerCourseFolderPerYear: 0,
            totalCostOfCourses: 0,
            monthlyCharge: 0,
            yearlyCharge: 0,
        };
    },
    mounted() {
        this.siteName = Dynamic.SITE_NAME;
        this.infoEmail = Dynamic.INFO_EMAIL;
        if (this.$route.query.inner) {
            this.$gtag.event("S.H.P. Company signup", {method: "Google"});
        } else if (this.$route.query.course_type) {
            this.$gtag.event("Sexual Harassment Prevention Company signup", {
                method: "Google",
            });
        } else {
            this.$gtag.event("Company signup", {method: "Google"});
        }
    },
    async created() {
        localStorage.removeItem("fname");
        localStorage.removeItem("lname");
        localStorage.removeItem("email");
        localStorage.removeItem("courses");
        if (this.$route.query.role) {
            this.role = this.$route.query.role;
        }

        this.$http.post("company/company_dropdown_data", {}).then((resp) => {
            for (let type of resp.data.companytype) {
                let obj = {
                    label: type.type,
                    value: type.id,
                };
                this.company_types.push(obj);
            }
        });
        await this.$http.get("user/discountRules", {params: {type: 'company'}}).then((resp) => {
            for (let course of resp.data.courses) {
                let obj = {
                    checked: false,
                    id: course.id,
                    course_name: course.name,
                    course_type: course.course_type,
                    cost: course.cost,
                    show_modal: course.show_modal,
                    show_number_of_users: course.show_number_of_users,
                    show_number_of_manager: course.show_number_of_manager,
                    show_discount_field: course.show_discount_field,
                };
                this.courses.push(obj);
            }
            this.course = this.courses;

            for (let coursefolder of resp.data.course_folders) {
                let obj1 = {
                    id: coursefolder.id,
                    coursefolder_name: coursefolder.folder_name,
                    member_price: coursefolder.member,
                    non_member_price: coursefolder.non_member,
                    attachedCourses: coursefolder.active_courses,
                };
                this.coursefolders.push(obj1);
            }
        });
    },
    methods: {
        showDone() {
            this.courseSelectionFocused = true;
        },
        doneClicked(e) {
            this.courseSelectionFocused = false;
        },
        showDoneButton() {
            this.courseFolderSelectionFocused = true;
        },
        doneButtonClicked(e) {
            this.courseFolderSelectionFocused = false;
        },
        cancelAgreement() {
            this.agreementModal = false;
        },
        showContactUs() {
            this.contactUsModal = true;
        },
        finalCreateAccount() {
            this.agreementModal = false;
            if (this.total_cost != 0) {
                this.showPaymentOption = true;
            } else {
                this.createAccount(this.formData);
            }
        },
        payClicked(cardData) {
            this.loading = true;
            this.paymentModalMessage = true;
            let payment = {
                payment_type: "",
                cardholder_street_address: cardData.address + "," + cardData.city + "," + cardData.state,
                cardholder_zip: cardData.zip,
                transaction_amount: "",
                token: cardData.token,
            };
            if (cardData.paymentType == "monthly") {
                payment.transaction_amount = this.monthlyCharge;
                payment.payment_type = "one-time";
            }
            if (cardData.paymentType == "yearly") {
                payment.transaction_amount = this.yearlyCharge;
                payment.payment_type = "yearly";
            }

            if (!this.checked_coursefolders.length) {
                payment.transaction_amount = this.totalPricePerYear.toFixed(2);
            }

            this.formData.payment = payment;
            this.formData.address_1 = cardData.address;
            this.formData.company_address_1 = cardData.address;
            this.formData.company_state = cardData.state;
            this.formData.company_city = cardData.city;
            this.formData.company_zip = cardData.zip;
            this.formData.promo_code = this.promo_code;
            this.formData.total_amount = this.sub_total;
            this.createAccount(this.formData);
        },
        showAgreement() {
            this.formData = {
                company_name: this.company.name,
                first_name: this.company.first_name,
                last_name: this.company.last_name,
                company_location_num: this.company.no_of_locations,
                company_employee_num: this.company.no_of_employees,
                company_address_1: this.company.address_1,
                company_address_2: this.company.address_2,
                company_phone: this.company.telephone_no,
                company_email: this.company.email,
                company_zip: this.company.zip,
                website: this.company.website,
                company_type: this.company.company_type,
                username: this.company.email,
                parent_id: this.company.parent_company,
                image: this.image,
                company_city: this.company.city,
                company_state: this.company.state,
                company_password: this.company.password,
                course_ids: this.checked_courses,
                courseFolders: this.checked_coursefolders,
                otherCourseFolders: this.checked_other_courses,
                already_member: this.already_member,
                member_id: this.member_id,
                status: 1,
                payment: [],
                card_info: [],
                i_agree: true,
            };
            this.agreementModal = true;
        },
        formatPrice(value) {
            return "$ " + Number(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        },
        checkIfCRAMember() {
            if (!this.already_member) {
                Swal.fire({
                    title: "Already a DRA Member?",
                    html: '<a target="_blank" href="https://www.delawarerestaurant.org/membership/"> Click here</a> to join.',
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-success btn-fill",
                    cancelButtonClass: "btn btn-danger btn-fill",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    buttonsStyling: false,
                    icon: "question",
                }).then((result) => {
                    if (result.value) {
                        this.inputStyle = "border:2px solid #f8981c;";
                        this.already_member = true;
                    } else {
                        this.submitLead();
                    }
                });
            } else {
                this.submitLead();
            }
        },
        submitLead() {
            if (this.company.no_of_employees !== "" && this.company.no_of_employees > 201) {
                return Swal.fire({
                    title: "Attention!",
                    html: "Please contact our sales team at <a href='mailto:dra@train321.com'>dra@train321.com</a> for special pricing.",
                    icon: "warning",
                    confirmButtonClass: "btn btn-success btn-fill",
                    confirmButtonText: "OK",
                });
            }
            if (this.company.no_of_employees !== "" && this.company.no_of_employees < 2) {
                return Swal.fire({
                    title: "Warning!",
                    html: "Based on the details provided, it appears you requesting a quote for an individual user.  Please click OK to proceed.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-success btn-fill",
                    cancelButtonClass: "btn btn-danger btn-fill",
                    confirmButtonText: "Ok",
                    cancelButtonText: "Cancel"
                }).then(result => {
                    if (result.value) {
                        window.location.href = "#/user_register";
                    }
                });
            }

            this.loading = true;
            let data = {
                company_name: this.company.name,
                first_name: this.company.first_name,
                last_name: this.company.last_name,
                number_of_locations: this.company.no_of_locations,
                number_of_employees: this.company.no_of_employees,
                email: this.company.email,
                phone_num: this.company.telephone_no,
                user_type: "corporate",
                already_member: this.already_member,
                member_id: this.member_id,
                course_ids: this.checked_courses,
                course_folders: this.checked_coursefolders,
                other_courses: this.checked_other_courses,
                promoCodes: this.promoCodes,
                selectedCoursesData: this.selectedCoursesData,
            };
            this.$http
                .post("user/lead", data)
                .then((resp) => {
                    this.lead_id = resp.data.user_id;
                    this.companyEstimateDetailModel = false;
                    this.total_cost = resp.data.total_cost;
                    this.sub_total = resp.data.sub_total;
                    this.employees_count = resp.data.number_of_employees;
                    this.locations_count = resp.data.number_of_locations;
                    this.showPricePlan = true;
                    this.perYearCost = resp.data.perYearCost;
                    this.yearDiscount = resp.data.yearDiscount;
                    this.perMonthCost = resp.data.per_month_total_cost;
                    this.totalPricePerMonth = resp.data.totalPricePerMonth;
                    this.discount = resp.data.discount;
                    this.total_discount = resp.data.discount;
                    this.totalPricePerYear = resp.data.totalPricePerYear;
                    this.pricePerCourseFolderPerMonth = resp.data.pricePerCourseFolderPerMonth;
                    this.pricePerCourseFolderPerYear = resp.data.pricePerCourseFolderPerYear;
                    this.totalCostOfCourses = resp.data.totalCostOfCourses;

                    // binding data to payment gateway

                    let yearlyCharge = 0;
                    let monthlyCharge = 0;

                    if (this.checked_courses.length > 0 && this.checked_coursefolders.length > 0) {
                        monthlyCharge = this.totalPricePerMonth;
                        yearlyCharge = this.totalPricePerYear;
                    }
                    if (this.checked_courses.length === 0 && this.checked_coursefolders.length > 0) {
                        monthlyCharge = this.totalPricePerMonth;
                        yearlyCharge = this.perYearCost;
                    }
                    if (this.checked_courses.length > 0 && this.checked_coursefolders.length === 0) {
                        yearlyCharge = this.totalPricePerYear;
                    }

                    this.monthlyCharge = monthlyCharge;
                    this.yearlyCharge = yearlyCharge;

                    this.loading = false;
                    this.paymentModalMessage = false;
                    this.companyDetails();
                }).catch((error) => {
                this.loading = false;
                return Swal.fire({
                    title: "Error!",
                    html: error.response.data.message,
                    icon: "error",
                });
            });
        },
        companyDetails() {
            this.showCompanyinformation = true;
        },
        companyEstimateDetail() {
            this.companyEstimateDetailModel = true;
        },
        hideModel() {
            this.companyEstimateDetailModel = false;
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
        },
        cancelContact() {
            this.contactUsModal = false;
        },
        saveContact() {
            this.loading = true;
            let data = {
                name: this.contact.name,
                email: this.contact.email,
                phone: this.contact.phone,
                message: this.contact.message,
            };
            this.$http
                .post("user/contact", data)
                .then((resp) => {
                    this.contactUsModal = false;
                    this.loading = false;
                    Swal.fire({
                        title: "Success!",
                        text: resp.data.message,
                        icon: "success",
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.status === 422) {
                        let respmessage = error.response.data.message.replace(/,/g, "\n");
                        Swal.fire({
                            title: "Error!",
                            text: respmessage,
                            icon: "error",
                        });
                    }
                });
        },
        acceptNumber() {
            var x = this.company.telephone_no.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.company.telephone_no = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

            var y = this.contact.phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.contact.phone = !y[2] ? y[1] : "(" + y[1] + ") " + y[2] + (y[3] ? "-" + y[3] : "");
        },
        onImageChange(e) {
            let files = e;
            if (!files.length) return;
            this.createImage(files[0]);
        },
        createImage(file) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        createAccount(formDataSubmitted) {
            delete this.$http.defaults.headers["authorization"];
            this.creatAccountClicked = true;
            formDataSubmitted.total_amount = this.sub_total;
            formDataSubmitted.promoCodes = this.promoCodes;
            this.formData.monthlyCharge = this.monthlyCharge;
            this.formData.yearlyCharge = this.yearlyCharge;
            this.formData.selectedCoursesData = this.selectedCoursesData;
            this.formData.totalCost = this.pricePerCourseFolderPerMonth + this.totalCostOfCourses + this.pricePerCourseFolderPerMonth + this.perYearCost;
            this.loading = true;
            this.$http
                .post("company/register", formDataSubmitted)
                .then((resp) => {
                    let ids = [];
                    let obj = {
                        id: resp.data.id,
                    };
                    ids.push(obj);
                    this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                    this.paymentModalMessage = false;
                    this.$http
                        .post("company/welcome_email", {
                            form_data: formDataSubmitted,
                            password: this.company.password,
                            ids: ids,
                        }).then((resp) => {
                        this.$router.push("/login");
                        Swal.fire({
                            title: "Success!",
                            text: `Account created successfully.`,
                            icon: "success",
                        });
                    }).catch((error) => {
                        this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                        this.paymentModalMessage = false;
                        this.$router.push("/login");
                        return Swal.fire({
                            title: "Success!",
                            text: "Account created successfully.",
                            icon: "success",
                        });
                    });
                }).catch((error) => {
                this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                return Swal.fire({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                });
            });
        },
        applyPromoCode() {
            this.submitLead();
            // this.loading = true;
            // let data = {
            //     amount: this.sub_total,
            //     promocode: this.promo_code,
            // }
            // this.$http.post("promocode/apply_promocode", data).then(resp => {
            //     this.sub_total = resp.data.final_amount;
            //     this.course_cost = resp.data.previous_amount;
            //     this.discounted_cost = resp.data.discounted_amount;
            //     this.discount_percentage = resp.data.discount_percentage;
            //     this.promoCodeDetails.discount_percentage = resp.data.discount_percentage;
            //     this.promoCodeDetails.discount_fixed_price = resp.data.discount_fixed_price;
            //     this.promoCodeDetails.type = resp.data.discount_type;
            //     this.loading = false;
            //     this.isPromoCodeValid = true;
            //     this.submitLead();
            // }).catch((error) => {
            //     this.loading = false;
            //     this.isPromoCodeValid = false;
            //     return Swal.fire({
            //         title: "Error!",
            //         html: error.response.data.message,
            //         icon: "error"
            //     });
            // });
        },
        handleCourseSelection: function (isChecked, course) {
            this.currentCheckedCourse.numberOfUsers = this.currentCheckedCourse.numberOfManager = this.currentSelectedCourse = 0;
            this.currentSelectedCourse = course.id;

            if (isChecked === false) {
                this.selectedCoursesData.forEach((courseData, index) => {
                    if (courseData.course_id === course.id) {
                        this.selectedCoursesData.splice(index, 1);
                    }
                });
            }

            if (course.show_modal && isChecked == true) {
                this.currentCheckedCourse.courseName = course.course_name;
                this.currentCheckedCourse.showUserFiled = course.show_number_of_users === 1 ? true : false;
                this.currentCheckedCourse.showManagerFiled = course.show_number_of_manager === 1 ? true : false;
                this.showCourseModal = true;
            }
        },
        handleAddUserCountToCourse: function () {
            let courseDetails = null;
            this.courses.forEach(course => {
                if (course.id === this.currentSelectedCourse) {
                    courseDetails = course;
                }
            });

            let data = {
                course_id: courseDetails.id,
            };

            if (courseDetails.show_number_of_users) {
                if(this.currentCheckedCourse.numberOfUsers === 0) {
                    this.checked_courses.forEach((courseId, index) => {
                        if(courseId === courseDetails.id) {
                            this.checked_courses.splice(index, 1);
                        }
                    });
                    this.showCourseModal = false;
                    return false;
                }
                data.users = this.currentCheckedCourse.numberOfUsers;
            }
            if (courseDetails.show_number_of_manager) {
                data.managers = this.currentCheckedCourse.numberOfManager;
            }

            this.selectedCoursesData.push(data);

            this.showCourseModal = false;
        },
        reEstimatePrice: function () {
            this.showReEstimatButton = !this.showReEstimatButton;
            this.disableSignUpButton = false;
            this.submitLead();
        },
        courseFolderChecked: function () {
            if (this.checked_coursefolders.length > 0) {
                this.checked_coursefolders = [];
                return true;
            }
            this.coursefolders.forEach(courseFolder => {
                this.checked_coursefolders.push(courseFolder.id);
            })
        },
        addPromoCode: function () {
            this.promoCodes.push('');
        },
        removePromoCode: function (index) {
            this.promoCodes.splice(index, 1);
        }
    },
    watch: {
        checked_courses: function () {
            this.showPromoField = false;
            this.alcoholCourses.forEach((id) => {
                if (this.checked_courses.includes(id) === true) {
                    this.showPromoField = true;
                }
            });

            this.showReEstimatButton = true;
            this.disableSignUpButton = true;
            this.showCompanyinformation = false
        },
        checked_coursefolders: function () {
            this.showCompanyinformation = false;
            this.showReEstimatButton = true;
            this.disableSignUpButton = true;
        },
        already_member: function () {
            this.showCompanyinformation = false;
            this.showReEstimatButton = true;
            this.disableSignUpButton = true;
        }
    }
};
</script>
<style scoped>

.payment-modal-text {
    animation: textBlink 2s linear infinite;
}
@keyframes textBlink{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
.form-section {
    background-color: transparent;
    border-right: 1px solid #999999;
}

.course-section {
    padding: 40px;
    background-color: #ffffff !important;
}

.py-5 {
    padding-bottom: 0px !important;
}

.mt--6 {
    margin-top: -6rem !important;
}

.mt--12 {
    margin-top: -12rem !important;
}

.search-wrapper {
    position: relative;
}

.courseList {
    max-height: 250px !important;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
}

hr {
    margin-top: 2px !important;
    margin-bottom: 20px !important;
}

.basebutton.disabled:hover {
    cursor: not-allowed;
}

@media (min-width: 992px) {
    .pt-lg-9,
    .py-lg-9 {
        padding-top: 3rem !important;
    }

    .password-eye span {
        border: 1px solid #808080b3;
        padding: 8px;
        border-radius: 5px;
        background: #80808029;
    }
}

#selected_course li {
    font-size: 0.89em;
}

#serviceAgreement {
    float: left;
    height: 300px;
    overflow: auto;
}

#serviceAgreement p {
    font-size: 0.81rem;
    text-align: justify;
}

.el-select-group__title {
    text-decoration: underline !important;
    font-weight: bolder !important;
}

.el-select-dropdown__item {
    font-size: 13px !important;
}

.reduceFont {
    font-weight: 400 !important;
}

.price-area .row {
    margin-top: 5px;
}

.bg-gradient-primary {
    background: linear-gradient(87deg, #07c9fb 0, #ffffff 100%) !important;
}

.req {
    color: red;
}

.knowMore-info {
    font-size: 13px;
    margin-left: 6px;
    text-decoration: underline;
    font-style: italic;
    color: #a8312d !important;
    cursor: pointer;
}

.provideScroller {
    padding-top: 10px;
    overflow-y: auto !important;
    max-height: 250px !important;
}

.login-inputs {
    padding-top: 20px;
}

.ctm-btn {
    background-color: #a8312d;
    color: #ffffff;
    border-radius: 0 !important;
    font-size: 17px;
    margin-right: 5px;
}

.login {
    margin-right: 0px !important;
}

.logo_img {
    width: 100px;
    padding: 10px;
}

.signup-text {
    position: absolute;
    right: 130px;
    top: 20px;
    z-index: 9;
    font-weight: 700;
}

.login-text {
    position: absolute !important;
}

@media only screen and (max-width: 1536px) {

    .content-area {
        padding-bottom: 100px;
    }

    section.register-page {
        padding: 20px;
    }
}

.promoBTN {
    background: #008000;
    padding: 5px 15px;
    color: #fff;
    margin-bottom: 3px;
}

.promoBTN:hover {
    transform: translateY(0px);
    background: #008000;
    color: #fff;
}

.promoBTN_Minus {
    background: #a8312d;
    padding: 5px 15px;
    color: #fff;
    margin-bottom: 3px;
}

.promoBTN_Minus:hover {
    transform: translateY(0px);
    background: #a8312d;
    color: #fff;
}

.promo_inputBOX label {
    display: block;
}


</style>
